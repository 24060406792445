@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Rubik+Mono+One&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400..900&display=swap');

:root {
    font-family: "Inter", sans-serif; 
   
  }
.rubik-text {
    font-family: 'Rubik Mono One', sans-serif;
  }
.title-text {
    font-family: 'Orbitron', sans-serif;
  }

