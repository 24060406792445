/* card.css */

/* Date Headerのスタイル */
.date-header {
    position: relative;
    padding-bottom: 10px;
    text-align: left;
    font-size: 1vw;
    margin: 10px 0;
    width: 100%;
    align-self: flex-start;
  }
  
  .date-header::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px;
    width: 100%;
    background: linear-gradient(to right, #f27121 20%, #ffffff 80%);
  }
  
  @media (max-width: 416px) {
    .date-header {
      font-size: 5vw;
      padding-bottom: 4vw;
      margin: 8vw 0;
    }
  
    .date-header::after {
      height: 3px;
    }
  }
  
  /* 画像コンテナのスタイル */
  .card_img {
    width: 100%;
    height: 300px; /* 必要に応じて高さを調整 */
    overflow: hidden;
    position: relative;
  }
  
  .card_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
  
  /* カード全体のスタイル */
  .card {
    width: 350px;
    background: #141414;
    border-radius: 16px;
    backdrop-filter: blur(20px);
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    /* pointer-events: none; を削除 */
    margin-left: 1.5vw;
    display: flex;
    flex-direction: column;
    align-items: center; /* すべての子要素を中央揃え */
  }
  
  @media (max-width: 416px) {
    .card {
      margin-left: 5vw;
    }
  }
  @media (max-width: 768px) {
    .card {
      width: 280px;
      padding: 15px;
    }
  }
  
  /* 小型タブレット / 大型スマートフォン (例: 576px以下) */
  @media (max-width: 576px) {
    .card {
      width: 260px;
      padding: 12px;
    }
  }
  
  /* スマートフォンサイズ (416px以下) */
  @media (max-width: 416px) {
    .card {
      width: 320px;
      margin-left: 5vw;
      padding: 10px;
    }
  }
  
  /* カード内のヘッダーのスタイル */
  .card_header {
    margin-bottom: 16px;
    text-align: center;
  }
  
  .card_header .title {
    font-size: 1vw;
    font-weight: bold;
  }
  
  @media (max-width: 416px) {
    .card_header .title {
      font-size: 6vw;
    }
  }
  
  .card_header .sub_title {
    font-size: 1vw;
    
  }
  
  @media (max-width: 416px) {
    .card_header .sub_title {
      font-size: 5vw;
    }
  }
  
  /* コンテナのスタイル */
  .cardcontainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    
    flex-direction: column;
  }
  
  .cardcontainer .headings {
    width: 60%;
    margin-bottom: 2vw;
  }
  
  @media (max-width: 416px) {
    .cardcontainer .headings {
      width: 95%;
    }
  }
  
  .cardcontainer .headings h1 {
    font-size: 3vw;
    text-align: center;
    padding: 0 20px;
    color: white;
    text-decoration: underline;
    text-decoration-color: #f27121;
    text-underline-offset: 0.5vw;
  }
  
  @media (max-width: 416px) {
    .cardcontainer .headings h1 {
      font-size: 5vw;
    }
  }
  
  .slider_wrapper {
    width: 90%;
    overflow: hidden;
    padding: 20px;
    display: flex;
  }
  
  @media (max-width: 416px) {
    .slider_wrapper {
      width: 100%;
    }
  }
  
  .inner_crousel {
    display: flex;
  }
  