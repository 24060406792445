.work__filter{
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 0.75rem;
    margin-bottom: 3rem;
}
.work__item{
    padding: 0.25rem 0.75rem;
    border-radius: 0.5rem;
    background-color:white;
    font-weight: var(--font-medium);
    text-transform: capitalize;
    cursor: pointer;
    color: black;
}
.work__item:hover{
    background-color: #F97316 ;
    color: white;
}
.work__container {
    grid-template-columns: repeat(2,max-content);
    gap: 2.5rem;
    justify-content: center;
    align-items: center;
}
.work__card{
    background-color: #141414;
    border: 1px solid white;
    padding: 1.25rem;
    border-radius: 1rem;
    height: 250px; /* 固定の高さを設定 */
}
.work__img {
    width: 295px;
    border-radius: 1rem;
    margin-bottom: var(--m-1);
}
/* .work__title{
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
    margin-bottom: var(--m-0-5);
} */

/* .work__button{
    color:white;
    font-size: var(--small-font-size);
    display: flex;
    align-items: center;
    column-gap: 0.25rem; 
    cursor: pointer;
} */
.work__button:hover .work__icon{
    transform: translateX(0.25rem);
}

body.active-modal {
    overflow-y: hidden;
}

.btn-modal {
    padding: 10px 20px;
    display: block;
    margin: 100px auto 0;
    font-size: 18px;
}

.modal, .overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
}

.overlay {
    background: rgba(49,49,49,0.8);
}
.modal-content {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #f1f1f1;
    padding: 14px 28px;
    border-radius: 3px;
    max-width: 600px;
    min-width: 300px;
}

.close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 7px;
}

.modal-content {
    width: 90%;
    max-width: 800px;
    background: #141414;
    padding: 20px;
    border-radius: 10px;
  }
  
  .modal-layout {
    display: flex;
    justify-content: space-between;
  }
  
  .modal-left {
    width: 50%;
    padding-right: 20px;
  }
  
  .modal-right {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-title {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .modal-description {
    margin-bottom: 20px;
  }
  
  .modal-social-icons {
    display: flex;
    gap: 10px;
  }
  
  .social-icon {
    font-size: 24px;
    color: #333;
  }
  
  .modal-img {
    max-width: 100%;
    max-height: 300px;
    object-fit: cover;
  }
  
  .close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: #141414;
    padding: 20px;
    border-radius: 8px;
    max-width: 80%;
    max-height: 80%;
    overflow-y: auto;
    z-index: 1001;
    
  }

  